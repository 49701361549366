import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, map, tap, delay, switchMap } from 'rxjs/operators';
import { Usuario } from '../models/usuario';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {


  endpoint = `https://igcpa.org.gt/wp-json/wp/v2/`;
  //ERROR DE API IGCPA: "No tienes suficientes permisos para acceder a este endpoint. El acceso a las peticiones de la REST API está restringido por los ajustes de iThemes Security."
  allPosts = null;
  pages: any;


  constructor( private http: HttpClient) { }


  getAllPosts(page, categoria): Observable<any[]> {
    let options = {
      observe: "response" as 'body',
      params: {
        per_page: '100',
        page: ''+page
      }
    };
 
    // console.log(page);
    //    return this.http.get<any[]>(`${this.endpoint}posts?_embed&categories=${categoria}`, options)
    return this.http.get<any[]>(`${this.endpoint}posts?_embed&categories=${categoria}`, options)
    .pipe(
      map(res => {
        this.pages = res['headers'].get('x-wp-totalpages');
        this.allPosts = res['headers'].get('x-wp-total');
        return res;
      })
    )
  }

  getPostsCategory(page = 1, categoria): Observable<any[]> {
    let options = {
      observe: "response" as 'body',
      params: {
        per_page: '50',
        page: ''+page
      }
    };
 
    return this.http.get<any[]>(`${this.endpoint}posts?_embed&categories=${categoria}`, options)
    .pipe(
      map(res => {
        this.pages = res['headers'].get('x-wp-totalpages');
        this.allPosts = res['headers'].get('x-wp-total');
        return res['body'];
      })
    )
  }

  

  postDetails(id) {
    return this.http.get(`${this.endpoint}posts/${id}?_embed`)
    .pipe(
      map((post) => {
        return post;
      })
    )
  }


}
