export class RecuperacionContrasenia {

    public correo: string = "";
    public token: string = "";
    public nuevaContrasenia: string = "";

    constructor(correo:string,token: string,nuevaContrasenia: string ){
        this.correo = correo;
        this.token = token;
        this.nuevaContrasenia = nuevaContrasenia;
       
    }

    
}
